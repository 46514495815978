.transition-width {
    will-change: width;
    transition-property: width;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 300ms;
}

.transition-padding {
    transition-property: padding-bottom, padding-left, padding-right, padding-top;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 300ms;
}
