.password-meter {
    position: relative;
    height: 3px;
    background: #ddd;
    margin: 10px auto 5px;
    border-radius: 3px;

    &:after,
    &:before {
        content: "";
        height: inherit;
        background: transparent;
        display: block;
        border-color: #fff;
        border-style: solid;
        border-width: 0 5px;
        position: absolute;
        width: 20%;
        z-index: 10;
    }

    &:before {
        left: 20%;
    }

    &:after {
        right: 20%;
    }

}
