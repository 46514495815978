// Variables
@import "variables";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Source+Code+Pro:wght@300;400;500&display=swap');

@tailwind base;

@tailwind components;

@import "./custom-components.css";

@tailwind utilities;

@import "./custom-utilities";


@import "./parsey-theme-apply";

// Custom Styles In Partials
[v-cloak] {
    display: none;
}

@import "./partials/tooltips";

@import "./partials/password-meter";

@import "./partials/multiselect";


