fieldset[disabled] .multiselect {
    pointer-events: none
}

.multiselect__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 48px;
    height: 35px;
    background: #fff;
    display: block
}

.multiselect__spinner:after, .multiselect__spinner:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border-color: #41b883 transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent
}

.multiselect__spinner:before {
    animation: a 2.4s cubic-bezier(.41, .26, .2, .62);
    animation-iteration-count: infinite
}

.multiselect__spinner:after {
    animation: a 2.4s cubic-bezier(.51, .09, .21, .8);
    animation-iteration-count: infinite
}

.multiselect, .multiselect__input, .multiselect__single {
    font-family: inherit;
    @apply text-sm;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

.multiselect {
    box-sizing: content-box;
    //min-height: 40px;
    //text-align: left;
    @apply relative block text-gray-700 w-full ;
}

.multiselect * {
    box-sizing: border-box
}

.multiselect:focus {
    outline: none
}

.multiselect--disabled {
    opacity: .6
}

.multiselect--active {
    z-index: 1
}

.multiselect--active:not(.multiselect--above) .multiselect__current, .multiselect--active:not(.multiselect--above) .multiselect__input, .multiselect--active:not(.multiselect--above) .multiselect__tags {
    //border-bottom-left-radius: 0;
    //border-bottom-right-radius: 0
}

.multiselect--active .multiselect__select {
    transform: rotate(180deg)
}

.multiselect--above.multiselect--active .multiselect__current, .multiselect--above.multiselect--active .multiselect__input, .multiselect--above.multiselect--active .multiselect__tags {
    //border-top-left-radius: 0;
    //border-top-right-radius: 0
}

.multiselect__input, .multiselect__single {
    //position: relative;
    //display: inline-block;
    //min-height: 20px;
    //line-height: 20px;
    border: none;
    //border-radius: 5px;
    //background: #fff;
    padding: 0;
    //width: 100%;
    //transition: border .1s ease;
    //box-sizing: border-box;
    //margin-bottom: 8px;
    //vertical-align: top
}


.parsey-select-input {

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
        @apply text-gray-700;
    }

    /* clears the 'X' from Internet Explorer */
    &::-ms-clear {  display: none; width : 0; height: 0; }
    &::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }

    @apply text-sm p-0 py-2 text-gray-700 border-none placeholder-gray-500 w-auto;

}

.multiselect__tag ~, .multiselect__tag ~ .multiselect__single {
    width: auto
}

.parsey-select-input:focus, .multiselect__single:focus {
    border: none;
    box-shadow: none;
    outline: none
}

.parsey-select-input::placeholder {
    @apply text-gray-400;
}

.multiselect__single {
    //padding-left: 5px;
    //margin-bottom: 8px
}

.multiselect__tags-wrap {
    display: inline-block;
    padding: .375rem 0 0;
}

.multiselect__tags {
    @apply block p-1 pr-10 pl-3 border border-gray-100 rounded;
}

.multiselect--active .multiselect__tags {
    border-color: transparent;
}

.multiselect__tag {
    @apply relative inline-flex items-center px-2.5 py-0.5 mr-3 mb-1.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800 truncate;
    max-width: 100%;
}

.multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all .2s ease;
    border-radius: 5px
}

.multiselect__tag-icon:after {
    content: "\D7";
    color: #266d4d;
    font-size: 14px
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: #369a6e
}

.multiselect__tag-icon:focus:after, .multiselect__tag-icon:hover:after {
    color: #fff
}

.multiselect__current {
    min-height: 40px;
    overflow: hidden;
    //padding: 8px 12px 0;
    padding-right: 30px;
    white-space: nowrap;
    //border-radius: 5px;
    //border: 1px solid #e8e8e8
}

.multiselect__current, .multiselect__select {
    line-height: 16px;
    box-sizing: border-box;
    display: block;
    margin: 0;
    text-decoration: none;
    cursor: pointer
}

.multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform .2s ease
}

.multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #999 transparent transparent;
    content: ""
}

.multiselect__placeholder {
    @apply inline text-gray-400;
}

.multiselect--active .multiselect__placeholder {
    display: none
}

.multiselect__content-wrapper {
    max-height: 240px;
    z-index: 1;
    min-width: 300px;
    -webkit-overflow-scrolling: touch;
    @apply absolute block bg-white w-full overflow-auto rounded border border-gray-100 mt-1 shadow-md;
}

.multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top
}

.multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    //border-bottom: none;
    //border-top: 1px solid #e8e8e8;
    @apply mb-1;
}

.multiselect__content::webkit-scrollbar {
    display: none
}

.multiselect__element {
    display: block
}

.multiselect__option {
    text-decoration: none;
    text-transform: none;
    white-space: nowrap;
    @apply p-3 pr-12 block relative cursor-pointer align-middle;

    &::after {
        @apply block absolute top-0 right-0 mr-5 h-full w-4;
    }

}

.grouped {
    .multiselect__option {
        @apply p-3 pl-10 pr-12;
    }

    .multiselect__option--group {
        @apply p-3;
    }
}

.multiselect__option--highlight {
    //background: #41b883;
    outline: none;
    //color: #fff;
    @apply text-white bg-indigo-400;
}

.multiselect__option--highlight:after {
    content: attr(data-select);
    //background: #41b883;
    //color: #fff;
    @apply text-white bg-indigo-400;
}

.multiselect__option--selected {

    @apply font-medium text-gray-900;

    &::after {
        content: "";
        background: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 20 20' fill='rgba(64, 85, 168)'><path fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd' /></svg>") center center no-repeat;
    }

    &.multiselect__option--highlight {
        @apply text-white bg-indigo-400;

        &::after {
            content: "";
            background: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 20 20' fill='white'><path fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd' /></svg>") center center no-repeat;
        }

    }


}


.multiselect--disabled {
    //background: #ededed;
    pointer-events: none
}

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select, .multiselect__option--disabled {
    //background: #ededed;
    @apply text-gray-400;
}

.multiselect__option--disabled {
    cursor: text;
    pointer-events: none
}

.multiselect__option--group {
    @apply text-gray-500 font-medium;
}

.multiselect__option--group.multiselect__option--highlight {
    //background: #35495e;
    //color: #fff
}

.multiselect__option--group.multiselect__option--highlight:after {
    //background: #35495e
}

.multiselect__option--disabled.multiselect__option--highlight {
    //background: #dedede
    @apply text-gray-500;
}

.multiselect__option--group-selected.multiselect__option--highlight {
    //background: #ff6a6a;
    //color: #fff;
    @apply font-medium text-gray-900;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
    //background: #ff6a6a;
    //content: attr(data-deselect);
    //color: #fff
}

.multiselect-enter-active, .multiselect-leave-active {
    transition: all .15s ease
}

.multiselect-enter, .multiselect-leave-active {
    opacity: 0
}

.multiselect__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top
}

[dir=rtl] .multiselect {
    text-align: right
}

[dir=rtl] .multiselect__select {
    right: auto;
    left: 1px
}

[dir=rtl] .multiselect__tags {
    padding: 8px 8px 0 40px
}

[dir=rtl] .multiselect__content {
    text-align: right
}

[dir=rtl] .multiselect__option:after {
    right: auto;
    left: 0
}

[dir=rtl] .multiselect__clear {
    right: auto;
    left: 12px
}

[dir=rtl] .multiselect__spinner {
    right: auto;
    left: 1px
}

@keyframes a {
    0% {
        transform: rotate(0)
    }
    to {
        transform: rotate(2turn)
    }
}
